<template>
	<!-- não funciona -->
	<div class="my-account">
		<div class="header-menu va-row">
			<div class="va-row">
				<div class="flex container-ddh-menu xs12 with-bottom va-row">
					<div class="flex xs6 no-padding">
						<h1>Minhas Turmas</h1>
					</div>
					<div class="flex xs6 no-padding text-right">
						<br /><br />

						<button
							id="btn-add-class"
							v-on:click="showGrade(emptyGrade, 'add')"
							class="btn-home--minhas-turmas"
						>
							ADICIONAR TURMA
						</button>
					</div>
				</div>
			</div>
			<div class="clearfix"></div>
		</div>

		<div class="container-dhh va-row">
			<div class="flex md12 xs12 no-padding margin-topo-lg"></div>
			<div class="flex md12 xs12 no-padding">
				<div class="ui-grid">
					<div class="my-data-table va-row" id="cards">
						<div class="flex xs12 no-padding fiscal-notes">
							<div class="body-spacing">
								<div class="va-row">
									<div
										class="sets-list table-list-simple team"
										v-if="list.length > 0"
									>
										<div class="va-row header no-padding">
											<div class="flex item">TURMA</div>

											<div class="flex item teacher">PROFESSOR</div>

											<!-- <div class="flex item area">DATA DE PAGAMENTO</div> -->
											<div class="flex item books-created">
												LIVROS CRIADOS / ALUNOS
											</div>

											<!-- <div class="flex item">LISTA DE ALUNOS</div> -->
											<div class="flex item books-created">STATUS</div>
											<!-- <div class="flex item">LIVROS CRIADOS</div> -->
										</div>

										<div
											class="va-row lines"
											v-for="item in list"
											:key="item.id"
										>
											<div class="flex item">
												{{ item.name }}
											</div>
											<div class="flex item teacher">
												{{ item.teacher }}
											</div>

											<div class="flex item books-created">
												{{ item.books_completed }} / {{ item.books_count }}
											</div>

											<div
												class="flex item status"
												:class="item.status_id == 20 ? 'blue' : ''"
												v-if="item.status_id == 20"
											>
												<div>Em progresso</div>
											</div>
											<div
												class="flex item status"
												v-if="item.status_id == 21"
												:class="item.status_id > 20 ? 'green' : ''"
											>
												<div>Liberado para impressão</div>
											</div>
											<div
												class="flex item status"
												v-if="item.status_id == 22"
												:class="item.status_id > 20 ? 'green' : ''"
											>
												<div>Tudo certo, imprimindo</div>
											</div>

											<!-- <div class="flex item text-right">
												<div>
													<button
														v-on:click.stop.prevent="showGrade(item, 'delete')"
														class="btn-change-edit"
													>
														<svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
															<path
																d="M9.66764 27.9991C9.15672 27.9991 8.70967 27.7991 8.32648 27.3991C7.9433 26.9991 7.7517 26.5325 7.7517 25.9991V6.99913H7.40045C7.1237 6.99913 6.89486 6.90469 6.71391 6.7158C6.53296 6.52691 6.44248 6.28802 6.44248 5.99913C6.44248 5.71024 6.53296 5.47135 6.71391 5.28246C6.89486 5.09358 7.1237 4.99913 7.40045 4.99913H12.4457C12.4457 4.71024 12.5362 4.47135 12.7172 4.28246C12.8981 4.09357 13.127 3.99913 13.4037 3.99913H19.9179C20.1946 3.99913 20.4235 4.09357 20.6044 4.28246C20.7854 4.47135 20.8759 4.71024 20.8759 4.99913H25.9212C26.1979 4.99913 26.4267 5.09358 26.6077 5.28246C26.7886 5.47135 26.8791 5.71024 26.8791 5.99913C26.8791 6.28802 26.7886 6.52691 26.6077 6.7158C26.4267 6.90469 26.1979 6.99913 25.9212 6.99913H25.5699V25.9991C25.5699 26.5325 25.3783 26.9991 24.9951 27.3991C24.6119 27.7991 24.1649 27.9991 23.654 27.9991H9.66764ZM13.0525 22.1325C13.0525 22.4214 13.1429 22.6602 13.3239 22.8491C13.5048 23.038 13.7337 23.1325 14.0104 23.1325C14.2872 23.1325 14.516 23.038 14.697 22.8491C14.8779 22.6602 14.9684 22.4214 14.9684 22.1325V10.8325C14.9684 10.5436 14.8779 10.3047 14.697 10.1158C14.516 9.92691 14.2872 9.83246 14.0104 9.83246C13.7337 9.83246 13.5048 9.92691 13.3239 10.1158C13.1429 10.3047 13.0525 10.5436 13.0525 10.8325V22.1325ZM18.3532 22.1325C18.3532 22.4214 18.4437 22.6602 18.6246 22.8491C18.8056 23.038 19.0344 23.1325 19.3112 23.1325C19.5879 23.1325 19.8168 23.038 19.9977 22.8491C20.1787 22.6602 20.2691 22.4214 20.2691 22.1325V10.8325C20.2691 10.5436 20.1787 10.3047 19.9977 10.1158C19.8168 9.92691 19.5879 9.83246 19.3112 9.83246C19.0344 9.83246 18.8056 9.92691 18.6246 10.1158C18.4437 10.3047 18.3532 10.5436 18.3532 10.8325V22.1325Z"
																fill="#4965BC" />
														</svg>

													</button>
												</div>
											</div> -->
										</div>
									</div>
								</div>

								<vuestic-modal
									:show.sync="show"
									v-bind:large="true"
									ref="gradeModal"
									:okText="'modal.confirm' | translate"
									:cancelText="'modal.cancel' | translate"
									:noButtons="true"
								>
									<div slot="title" v-if="gradeModalType === 'view'">
										Visualizar turma
									</div>
									<div slot="title" v-if="gradeModalType === 'edit'">
										Editar turma
									</div>
									<div slot="title" v-if="gradeModalType === 'delete'">
										Excluir turma
									</div>
									<div slot="title" v-if="gradeModalType === 'add'">
										Adicionar turma
									</div>

									<div v-if="gradeSelected !== null">
										<grade-edit
											:grade="gradeSelected"
											:school="schoolSelected"
											:typeSend="'post'"
											v-if="gradeModalType === 'add'"
										></grade-edit>
										<grade-edit
											:grade="gradeSelected"
											:school="schoolSelected"
											:typeSend="'put'"
											v-if="gradeModalType === 'edit'"
										></grade-edit>

										<grade-view
											:grade="gradeSelected"
											v-if="gradeModalType === 'view'"
										></grade-view>
										<grade-delete
											:grade="gradeSelected"
											v-if="gradeModalType === 'delete'"
										></grade-delete>
									</div>
								</vuestic-modal>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="flex md12 xs12 no-padding">
				<div class="ui-grid">
					<div class="flex md12 xs12 no-padding">
						<h4></h4>
					</div>

					<div class="va-row">
						<div class="flex xs12 no-padding fiscal-notes">
							<div class="body-spacing">
								<div class="va-row">
									<div class="sets-list table-list-simple books-list">
										<div class="va-row header">
											<div class="flex item book-cover-title">
												LIVROS DA ADOÇÃO
											</div>
											<div class="flex item">
												TOTAL ({{ totalQuantity }} un.)
											</div>
											<!-- <div class="flex item material-title">
												MATERIAL COMPLEMENTAR
											</div> -->
										</div>
										<div
											class="va-row lines"
											v-for="(product_item, id) in projectInfos.books"
											:key="id"
										>
											<div class="flex item book-cover-image">
												<img :src="product_item.thumbnail" alt="" width="150" />
											</div>
											<div class="flex item book-name">
												<h4>
													<span class="nome-da-marca">{{
														product_item.brand.name
													}}</span
													><br />{{ product_item.name }}
												</h4>
											</div>
											<div class="flex item book-quantity">
												{{ product_item.quantity }} unidades
											</div>
											<!-- <div class="flex item">Baixar em PDF</div> -->

											<div class="flex item action">
												<!-- <router-link
													:to="{
														name: 'manager-book',
														params: { book: item },
													}"
													class="btn-change-edit"
												>
													<span class="fa fa-eye"></span>
												</router-link> -->

												<!-- <button
													v-on:click.stop.prevent="showGrade(item, 'view')"
													class="btn-change-edit"
												>
													<span class="fa fa-eye"></span>
												</button> -->
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="flex md12 xs12 no-padding">
							<h4></h4>
						</div>
						<div class="flex xs12 no-padding fiscal-notes">
							<div class="body-spacing">
								<div class="va-row">
									<div class="sets-list table-list-simple users">
										<div class="va-row header">
											<div class="flex item acesso">ACESSO</div>
											<div class="flex item email">E-MAIL</div>
											<div class="flex item phone">TELEFONE</div>
											<div class="flex item type">TIPO DE ACESSO</div>
										</div>
										<div
											class="va-row lines"
											v-for="item in projectInfos.people"
											:key="item.email"
										>
											<div class="flex item acesso">
												{{ item.name }}
											</div>
											<div class="flex item email">
												{{ item.email }}
											</div>
											<div class="flex item phone">
												{{ item.phone }}
											</div>
											<div class="flex item type">
												{{ item.access_level }}
											</div>

											<div class="flex item action">
												<div>
													<!-- <button
														v-on:click.stop.prevent="showGrade(item, 'view')"
														class="btn-change-edit"
													>
														<span class="fa fa-eye"></span>
													</button> -->

													<!-- <button
														v-on:click.stop.prevent="showGrade(item, 'view')"
														class="btn-change-edit"
													>
														<span class="fa fa-trash"></span>
													</button> -->
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<!-- <div
								class="flex md12 text-right"
								v-if="
									'invoices' in projectInfos &&
									projectInfos.invoices.length < 1 &&
									'bills' in projectInfos &&
									projectInfos.bills.length < 1
								"
							>
								<button
									v-on:click="openModalConfirmation('adoption_delete', null)"
									class="btn btn-primary cancel exclude"
								>
									DELETAR ADOÇÃO
								</button>
							</div> -->
						</div>
					</div>
				</div>
			</div>

			<vuestic-modal
				:show.sync="show"
				v-bind:large="true"
				ref="optionModalModal"
				:okText="'modal.confirm' | translate"
				:cancelText="'modal.cancel' | translate"
				:noButtons="true"
				:cancelDisabled="true"
			>
				<div slot="title" v-if="typeModalconfirm === 'nf'">Nota fiscal</div>
				<div slot="title" v-if="typeModalconfirm === 'adoption_delete'">
					Excluir adoção
				</div>
				<div slot="title" v-if="typeModalconfirm === 'checkout'">
					Gerar pagamento
				</div>

				{{ confirmMessage }}

				<br /><br />

				<!-- {{ errors }} -->

				<div class="error-label text-center" v-if="errors.items[0]">
					<!-- {{ errors }} -->

					{{ errors.items[0].msg }}
				</div>

				<hr />
				<br />

				<div class="va-row text-right">
					<div class="flex md12 text-right no-padding">
						<button class="btn btn-primary cancel" @click="closeModal()">
							CANCELAR
						</button>

						<button
							class="btn right"
							v-if="typeModalconfirm === 'nf'"
							@click="sendNF(currentItemModal)"
						>
							CONFIRMAR
						</button>
						<button
							class="btn right"
							v-if="typeModalconfirm === 'adoption_delete'"
							@click="deleteAdoption(currentItemModal)"
						>
							EXCLUIR
						</button>
						<button
							class="btn right"
							v-if="typeModalconfirm === 'checkout'"
							@click="sendCheckout(currentItemModal)"
						>
							CONFIRMAR
						</button>
					</div>
				</div>
			</vuestic-modal>

			<vuestic-modal
				:show.sync="show"
				v-bind:large="true"
				ref="miniModal"
				:okText="'modal.confirm' | translate"
				:cancelText="'modal.cancel' | translate"
				:noButtons="true"
				:cancelDisabled="true"
			>
				<div slot="title">Sucesso</div>

				{{ customResponse }}

				<br /><br />
				<hr />
				<br />
				<div class="va-row">
					<div class="flex md12 text-right no-padding">
						<button class="btn btn-white right cancel" @click="closeModal()">
							OK, ENTENDI
						</button>
					</div>
				</div>
			</vuestic-modal>

			<vuestic-modal
				:show.sync="show"
				v-bind:large="true"
				ref="miniModalBookEdit"
				:okText="'modal.confirm' | translate"
				:cancelText="'modal.cancel' | translate"
				:noButtons="true"
				:cancelDisabled="true"
			>
				<div slot="title">Editar produtos</div>

				<books-edit
					v-if="products"
					:products="products"
					:productsExtra="productsExtra"
					:projectInfos="projectInfos"
					@closeAndSaveBooksEdit="closeAndSaveBooksEdit()"
					@closeEditBooks="closeEditBooks()"
				/>

				<!-- {{ customResponse }} -->

				<!-- <br /><br />
				<hr />
				<br />
				<div class="va-row">
					<div class="flex md12 text-right no-padding">
						<button class="btn btn-white right cancel" @click="closeModal()">
							OK, ENTENDI
						</button>
					</div>
				</div> -->
			</vuestic-modal>
		</div>
	</div>
</template>

<script>
	// import { required, minLength, between } from 'vuelidate/lib/validators'
	import { mask } from "vue-the-mask";
	import { mapGetters } from "vuex";
	// import VuesticTreeRoot from './VuesticTreeRoot.vue'
	// import VuesticTreeNode from './VuesticTreeNode'
	// import VuesticTreeCategory from './VuesticTreeCategory'

	const axios = require("axios");

	export default {
		name: "GradesManager",
		directives: { mask },
		// components: { VuesticTreeCategory, VuesticTreeNode, VuesticTreeRoot },
		computed: {
			...mapGetters({
				principalCredential: "principalCredential",
				// ...
			}),
		},

		data() {
			return {
				list: [],
				projectInfos: [],
				products: [],
				productsExtra: [],
				editing: false,
				emptySchool: {
					name: "",
					address: {
						district: "",
						address_number: "",
						state: "",
						city: "",
						address1: "",
						address2: "",
						zip_postal_code: "",
					},
					contact_name: "",
					contact_email: "",
					contact_phone: "",
					type: 0,
					project_id: 1, //
				},

				emptyGrade: {
					role_id: "20",
					project_id: "1",
					name: "",
					grade_type_id: "",
					period_id: "",
					year: "",
					school_id: "",
					size: "",
					deadline: "",
					teacher: {
						email: "",
						name: "",
						phone: "",
						gender: "",
					},
				},

				schoolModalType: null,
				schoolSelected: null,

				gradeModalType: null,
				gradeSelected: null,

				files: "",
				show: false,

				customResponse: "",
				typeModalconfirm: "",
				confirmMessage: "",
				currentItemModal: null,
				totalQuantity: 0,
			};
		},

		mounted() {
			const self = this;

			this.$root.$on("closeModal", () => {
				self.closeModal();
			});

			this.$root.$on("deleteSchool", (school) => {
				var result = self.list.filter((item) => {
					return item.id === school.id;
				});

				var index = self.list.indexOf(result[0]);

				if (index > -1) {
					self.list.splice(index, 1);
				}
			});

			// CRUD SCHOOLS
			this.$root.$on("addGrade", (grade) => {
				// var result = self.list.filter(item => {
				//   return item.id === school.id
				// })

				// var indexSchool = self.list.indexOf(result[0]);

				self.list.push(grade);
			});

			this.$root.$on("editGrade", (grade) => {
				// var result = self.list.filter(item => {
				//   return item.id === school.id
				// })

				var resultGrade = self.list.filter((item) => {
					return item.id === grade.id;
				});

				//var grades = result[0].grades;
				//var indexSchool = self.list.indexOf(result[0]);
				var index = self.list.indexOf(grade);
				self.list.splice(index, 1, grade);
			});

			this.$root.$on("deleteGrade", (grade) => {
				// var result = self.list.filter(item => {
				//   return item.id === school.id
				// })

				var resultGrade = self.list.filter((item) => {
					return item.id === grade.id;
				});

				//var grades = result[0].grades;
				var index = self.list.indexOf(resultGrade[0]);

				if (index > -1) {
					self.list.splice(index, 1);
				}
			});

			// AJAX CALLS
			self.getGrades();
			self.getProjectDetails();
			self.getProducts();

			this.emptySchool.project_id = self.principalCredential.currentGrade;
			this.emptyGrade.project_id = self.principalCredential.currentGrade;
		},

		//props: ['address', 'subscription'],

		methods: {
			// viewGrade(item) {
			// 	alert("item");
			// },
			getGrades() {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.principalCredential.token,
					},
				};

				axios
					.get(
						process.env.VUE_APP_ROOT_API +
							"/grades/gradesByProject/" +
							self.principalCredential.currentAdoption.id,
						config
					)
					.then((response) => {
						self.list = response.data.data.grades;
					})
					.catch((e) => {
						this.errors.push(e);
					});
			},

			getProducts() {
				const self = this;
				self.products = [];

				let config = {
					headers: {
						Authorization: "Bearer " + this.principalCredential.token,
					},
				};

				axios
					.get(process.env.VUE_APP_ROOT_API + "/products", config)
					.then((response) => {
						self.products = response.data.data.products;
					})
					.catch((e) => {
						this.errors.push(e);
					});
			},

			jsonCopy(src) {
				return JSON.parse(JSON.stringify(src));
			},

			showSchool(school, type) {
				this.schoolModalType = type;

				let copy = this.jsonCopy(school);
				this.schoolSelected = copy;
				this.$refs.largeModal.open();

				//  this.$nextTick(() => {
				//  });
			},

			showGrade(grade, type) {
				this.gradeModalType = type;
				this.gradeSelected = this.jsonCopy(grade);

				//this.schoolSelected = school
				this.$refs.gradeModal.open();
			},

			closeEditBooks() {
				const self = this;

				self.disableProductsEdit();
			},

			closeAndSaveBooksEdit() {
				const self = this;

				self.disableProductsEdit();
				self.getProjectDetails();
			},

			closeModal() {
				if (this.$refs.largeModal) {
					this.$refs.largeModal.close();
				}

				if (this.$refs.gradeModal) {
					this.$refs.gradeModal.close();
				}

				if (this.$refs.miniModal) {
					this.$refs.miniModal.close();
				}

				if (this.$refs.optionModalModal) {
					this.$refs.optionModalModal.close();
				}

				this.gradeSelected = null;
			},

			clickOnFile() {
				this.$refs.files.click();
			},

			submitFiles() {
				let formData = new FormData();

				for (var i = 0; i < this.files.length; i++) {
					let file = this.files[i];
					formData.append("csv", file);
				}

				axios
					.post(process.env.VUE_APP_ROOT_API + "/schools/import", formData, {
						headers: {
							"Content-Type": "multipart/form-data",
							Authorization: "Bearer " + this.principalCredential.token,
						},
					})
					.then(function () {
						alert("Upload feito com sucesso");
					})
					.catch(function () {
						alert("Erro, upload falhou");
					});
			},

			handleFileUpload() {
				this.files = this.$refs.files.files;
				this.submitFiles();
			},

			saveAddress() {
				const self = this;

				let config = {
					onDownloadProgress: (progressEvent) => {
						let percentCompleted = Math.floor(
							(progressEvent.loaded * 100) / progressEvent.total
						);
						this.percentCall = percentCompleted;
						// do whatever you like with the percentage complete
						// maybe dispatch an action that will update a progress bar or something
					},
				};

				this.$validator.validateAll().then((result) => {
					if (result) {
						// eslint-disable-next-line

						// this.addressEdit.nameRecipient = this.addressEdit.name + " " + this.addressEdit.lastname
						this.addressEdit.nameRecipient =
							this.addressEdit.name + " " + this.addressEdit.lastname;
						this.subscriptionSend.address = this.addressEdit;

						if (this.isCreation) {
							axios
								.post(
									process.env.VUE_APP_ROOT_API + "/customer/address",
									this.subscriptionSend,
									config
								)
								.then(function (response) {
									if (response.data.success !== false) {
										self.$router.push({ name: "adresses" });
										self.percentCall = 0;
									} else {
										self.errors.push(response.data.errors[0]);
									}
								})
								.catch(function (error) {
									console.log(error);
								});
						} else {
							axios
								.put(
									process.env.VUE_APP_ROOT_API + "/customer/address",
									this.subscriptionSend,
									config
								)
								.then(function (response) {
									if (response.data.success !== false) {
										self.$router.push({ name: "adresses" });
										self.percentCall = 0;
									} else {
										self.errors.push(response.data.errors[0]);
									}
								})
								.catch(function (error) {
									console.log(error);
								});
						}
					}
				});
			},

			showSubscriberEditing() {
				this.subscriberEditing = false;
			},
			hideSubscriberEditing() {
				this.subscriberEditing = false;
			},

			scrollParent(slug) {
				this.$router.push({ name: slug });
			},

			enableProductsEdit() {
				this.editing = true;
				this.$refs.miniModalBookEdit.open();
			},

			disableProductsEdit() {
				this.editing = false;
				this.getProducts();
				this.getProjectDetails();

				this.$refs.miniModalBookEdit.close();
			},

			getProjectDetails() {
				const self = this;

				self.productsExtra = [];

				// AJAX CALLS
				let config = {
					headers: {
						Authorization: "Bearer " + this.principalCredential.token,
					},
				};
				axios
					.get(
						process.env.VUE_APP_ROOT_API +
							"/projects/get_project_details/" +
							self.principalCredential.currentAdoption.id,
						config
					)
					.then((response) => {
						self.projectInfos = response.data.data.details;
						var listProducts = [];
						self.totalQuantity = 0;

						if (self.projectInfos.books.length) {
							self.projectInfos.books.forEach((element) => {
								var newProduct = { id: element.id, quantity: element.quantity };
								self.totalQuantity = self.totalQuantity + element.quantity;

								listProducts.push(newProduct);
							});
						}
						self.productsExtra = listProducts;
					})
					.catch((e) => {});
			},

			openModalConfirmation(type, item) {
				const self = this;

				self.currentItemModal = item;

				self.$refs.optionModalModal.open();
				self.typeModalconfirm = type;

				if (type === "nf") {
					self.confirmMessage =
						"Ao confirmar será enviado a nota fiscal para o responsável da escola";
				} else if (type === "print") {
					self.confirmMessage =
						"Você deseja liberar os livros mesmo com pendências de pagamento?";
				} else if (type === "checkout") {
					self.confirmMessage =
						"Ao confirmar será enviado novamente o e-mail de cobrança para o responsável da escola";
				} else if (type === "adoption_delete") {
					self.confirmMessage =
						"Você tem certeza que deseja excluir esta adoção?";
				}
			},

			sendCheckout(item) {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.principalCredential.token,
					},
				};

				self.errors.items = [];

				axios
					.get(
						process.env.VUE_APP_ROOT_API +
							"/projects/send_checkout/" +
							self.principalCredential.currentAdoption.id,
						config
					)
					.then((response) => {
						self.closeModal();
						self.customResponse = response.data.data.msg;
						self.$refs.miniModal.open();
					})
					.catch(function (error) {
						let fieldError = {
							field: "error-modal",
							msg: error.response.data.message,
							rule: "error", // optional
						};
						self.$validator.errors.add(fieldError);
					});
			},

			deleteAdoption(item) {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.principalCredential.token,
					},
				};

				self.errors.items = [];

				axios
					.post(
						process.env.VUE_APP_ROOT_API +
							"/projects/delete/" +
							self.principalCredential.currentAdoption.id,
						[],
						config
					)
					.then((response) => {
						self.$router.push({ name: "adoptions-manager" });
						self.closeModal();
					})
					.catch(function (error) {
						console.log(error);
						let fieldError = {
							field: "error-modal",
							msg: error.response.data.message,
							rule: "error", // optional
						};
						self.errors.items.push(fieldError);
					});
			},

			sendNF(item) {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.principalCredential.token,
					},
				};

				self.errors.items = [];

				axios
					.get(
						process.env.VUE_APP_ROOT_API +
							"/projects/create_invoice/" +
							self.principalCredential.currentAdoption.id,
						config
					)
					.then((response) => {
						//alert("NF");
						//self.list = response.data.data.projects
						self.closeModal();
						self.customResponse = response.data.data.msg;
						self.$refs.miniModal.open();
					})
					.catch(function (error) {
						let fieldError = {
							field: "error-modal",
							msg: error.response.data.message,
							rule: "error", // optional
						};
						self.errors.items.push(fieldError);
					});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.hide {
		display: none;
	}
	@media (max-width: 580px) {
		.btn-primary {
			width: 100%;
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 5px;
			}
		}
	}

	// .fiscal-notes ::v-deep .form-group:last-child {
	// 	margin-bottom: 10px !important;
	// }

	.error-label {
		color: red;
		text-align: left;
		margin-bottom: 10px;
	}
	small {
		font-family: Roboto;
		font-size: 12px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #000000;
		text-indent: 15px;
		margin-top: -5px;
		display: block;
		margin-bottom: 10px;
		// padding-left: 15px;
	}
	h5 {
		font-family: "Nunito";
		font-size: 20px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
	}
	.address-info {
		margin-bottom: 15px;
	}
	.editing {
		margin-top: 15px;
	}
	.radio-button-wrapper {
		padding: 14px 11px;
		margin-top: 10px;

		@media (max-width: 990px) {
			padding: 20px 19px;
			margin-top: 10px;
		}
	}
	.header-menu-club {
		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #000000;
			margin-top: 50px;
			margin-bottom: 20px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}
	.add-new-card {
		border-radius: 10px;
		border: dashed 2px #2cd3d8;
		background-color: #ffffff;
		font-family: Nunito;
		font-size: 12px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 3.5;
		letter-spacing: 0.7px;
		text-align: center;
		color: #4965bc;
		margin-bottom: 20px;
		padding: 15px 0px;
		cursor: pointer;

		.plus {
			font-family: "Nunito";
			font-size: 35px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: 0.7px;
			text-align: center;
			color: #ffffff;
			width: 40px;
			height: 40px;
			transform: rotate(-180deg);
			border-radius: 40px;
			border: solid 1px #4965bc;
			background-color: #4965bc;
			display: block;
			margin: 0 auto;
			margin-top: 20px;
			line-height: 36px;
		}
	}

	// Default of page

	h4 {
		font-family: "Nunito";
		font-size: 24px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
		margin-bottom: 0;
	}
	.header-menu {
		background-color: white;

		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #68596e;
			margin-top: 34px;
			margin-bottom: 34px;
			line-height: 1;
			white-space: nowrap !important;
			width: 90%;
			overflow: hidden !important;
			text-overflow: ellipsis !important;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}
	.my-data {
		/* Facebook bar */
		.facebook-tag {
			height: 65px;
			border-radius: 5px;
			background-color: #3a5998;
			color: white;
			padding: 14px 20px;
			margin-bottom: 20px;

			svg {
				font-size: 26px;
				margin-right: 0px;
				float: left;

				path {
					fill: #fff;
				}
			}

			.name {
				display: inline-block;
				margin-top: 1px;
				line-height: 37px;
			}

			.facebook-toggle {
				float: right;
				display: block;
				margin-top: 5px;
			}
		}
	}

	.change-password {
		border-radius: 10px;
		border: solid 2px #f0ebf5;
		background-color: #ffffff;
		padding: 24px 20px;
		position: relative;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 7px;
			font-family: "Roboto";

			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
		}

		h5 {
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #afa2ba;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		.floated-icon {
			float: left;
			width: 36px;
			height: 36px;
			font-size: 30px;

			svg {
				position: relative;
				top: -5px;
				left: -3px;

				path {
					fill: #afa2ba;
				}
			}
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}
	}

	.my-data-table {
		h4 {
			margin-top: 50px;
			margin-bottom: 20px;
		}
	}
	.fiscal-notes {
		border-radius: 10px;
		background-color: transparent;
		position: relative;
		border: none;
		box-shadow: none !important;
		margin-bottom: 0px !important;

		.table-list-simple {
			margin: 0px;
		}

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		.body-spacing {
			// padding: 30px 30px 10px 30px;

			// @media (max-width: 990px) {
			// 	padding: 25px 15px 15px 15px;
			// }

			.btn-change-edit {
				border: none;
				color: #4965bc;
				background: transparent;
				font-size: 20px;
				padding: 0px 8px;

				&:hover {
					svg {
						path {
							fill: #ff4641;
						}
					}
				}
			}

			//  div:last-child{
			//   margin-bottom: 0px;
			// }
		}

		hr {
			border-top: solid 1px #f0ebf5;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		.dual-inputs {
			padding: 0px 8px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		.company-code {
			text-transform: capitalize;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}

		// .panel:last-child{
		//   margin-bottom: 0px;
		// }
	}

	.address-boxes {
		border-radius: 10px;
		border: solid 1px #f0ebf5;
		background-color: #ffffff;
		position: relative;
		border: solid 2px #f0ebf5;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		svg {
			path {
				fill: #afa2ba;
			}
		}

		.clube {
			svg {
				path {
					fill: #fff;
				}
			}
		}

		.body-spacing {
			padding: 30px 30px;

			@media (max-width: 990px) {
				padding: 25px 15px 15px 15px;
			}
		}

		hr {
			border-top: solid 2px #f0ebf5;
		}

		.dual-inputs {
			padding: 0px 8px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}

		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}

		.address-row {
			padding: 16px 0px;
			position: relative;
			border-bottom: solid 1px #f0ebf5;
			b {
				font-size: 16px;
				color: #000000;
			}
			:last-child {
				border-bottom: none;
			}
		}
	}

	.address-row {
		position: relative;
	}

	.more-cards {
		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}
	}

	.panel {
		// height: 200px;
		border-radius: 10px;
		border: solid 2px #9378f0;
		background-color: #ffffff;
		overflow: hidden;
		margin-bottom: 20px;

		.header-panel {
			padding: 0px 20px;
			background-color: #9378f0;
			line-height: 33px;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #ffffff;
		}

		.clube img {
			margin-top: -5px;
		}

		b {
			font-size: 16px;
			color: #000000;
		}

		.panel-body {
			position: relative;
			padding: 10px 0px;
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: #afa2ba;
			margin: 0px !important;

			.flex.xs1 {
				-ms-flex-preferred-size: 8.33333%;
				flex-basis: 6.33333%;
				-webkit-box-flex: 0;
				-ms-flex-positive: 0;
				flex-grow: 0;
				max-width: 6.33333%;
			}

			b {
				font-family: Roboto;
				font-size: 16px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.31;
				letter-spacing: normal;
				color: #000000;
			}

			.editor {
				right: 18px;
				top: 20%;
			}
		}
	}

	.subscription-box {
		// padding: 30px !important;

		font-family: Roboto;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.31;
		letter-spacing: normal;
		color: #000000;

		.toggled {
			margin-top: 10px;
		}
	}
	.newsletter {
		// margin-top:40px;
		padding: 31px;
		border: solid 2px #f0ebf5;

		@media (max-width: 990px) {
			margin-top: 0px;
			padding: 15px;
		}
	}

	// Custom Component
	.v-switch-button {
		::v-deep &:before {
			content: "";
			display: block;
			position: relative;
			background-color: #fff;
			width: 6px;
			height: 6px;
			border-radius: 14.5px;
			margin-left: auto;
			margin-right: auto;
			top: 9px;
		}
	}

	.vue-js-switch {
		&.toggled {
			.v-switch-button {
				::v-deep &:before {
					content: "";
					display: block;
					position: relative;
					background-color: #7ed321;
					width: 6px;
					height: 6px;
					border-radius: 14.5px;
					margin-left: auto;
					margin-right: auto;
					top: 9px;
				}
			}
		}
	}

	.name-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}
	.lastname-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}

	.created-data {
		font-size: 12px;
		display: block;
		color: #afa2ba;

		&.init {
			margin-bottom: 10px;
		}
		&.pay {
			cursor: pointer;
			color: #4965bc;
			// width: 100%;
			display: contents;

			&:hover {
				text-decoration: underline;
				color: #afa2ba !important;
			}
		}
	}

	.btn-edit {
		text-align: right;
	}

	.btn-group-custom {
		// place-self: flex-end;
		// display: inline-flex;
		// margin-bottom: 16px;
		align-items: center;
		display: flex;
	}

	.team {
		.item {
			width: 25% !important;
		}

		.teacher {
			width: 25% !important;
		}

		.books-created {
			width: 25% !important;
		}

		.status {
			position: relative;

			&:before {
				content: "";
				display: block;
				width: 6px;
				height: 6px;
				background-color: #000000;
				position: absolute;
				top: 0;
				bottom: 0;
				margin-top: auto;
				margin-bottom: auto;
				left: 24px;
				border-radius: 50%;
			}

			&.blue {
				&:before {
					background-color: #1890ff;
				}
			}

			&.green {
				&:before {
					background-color: #52c41a;
				}
			}

			div {
				padding-left: 16px;
			}
		}

		.link {
			// border-bottom: 1px solid #4965bc;
			color: #4965bc;
			text-decoration: underline;
			cursor: pointer;
			font-size: 14px;
		}

		.full-line {
			width: 100% !important;
			justify-content: center;
			text-align: center;
			margin-bottom: 0px;
		}

		.lines {
			background-color: #ffffff;

			.item {
				padding: 24px;
				// width: 16.6%;
			}
		}
	}

	.books-list {
		.book-cover-title {
			width: 67% !important;
			padding-left: 0px !important;
		}

		.book-cover-image {
			width: 16.6% !important;
			padding: 0;

			img {
				max-width: 150px !important;
			}
		}

		.book-name {
			width: 50% !important;
			h4 {
				font-family: "Roboto";
				font-style: normal;
				font-weight: 400;
				font-size: 14px;
				line-height: 21px;
				/* identical to box height, or 150% */

				/* color / neutral / darkest */

				color: #68596e;
			}
		}

		.book-quantity {
			width: 23% !important;
		}

		.material-title {
			width: 21%;
		}

		.action {
			width: 10% !important;
		}

		.link {
			// border-bottom: 1px solid #4965bc;
			color: #4965bc;
			cursor: pointer;
			text-decoration: underline;
			font-size: 14px;
		}

		.full-line {
			width: 100% !important;
		}

		.lines {
			background-color: #ffffff;

			.item {
				padding: 24px;
				// width: 16.6%;
			}
		}

		.action {
			text-align: right;
		}
	}

	.persons {
		.item {
			width: 25% !important;
		}

		.email {
			width: 25% !important;
		}

		.books-created {
			width: 25% !important;
		}

		.link {
			// border-bottom: 1px solid #4965bc;
			color: #4965bc;
			cursor: pointer;
			text-decoration: underline;
			font-size: 14px;
		}

		.full-line {
			width: 100% !important;
		}

		.lines {
			background-color: #ffffff;

			.item {
				padding: 24px;
				// width: 16.6%;
			}
		}
	}

	.users {
		.item {
			width: 16.6% !important;
		}

		.header {
			padding-left: 0px;
			padding-right: 0px;
		}

		.email {
			width: 30% !important;
			word-wrap: break-word;
			overflow-wrap: anywhere;
		}

		.phone {
			width: 20% !important;
		}
		// .acessos {
		// 	padding-left: 0;
		// 	padding-left: 0;
		// }

		// .email {
		// 	width: 30% !important;
		// }

		// .books-created {
		// 	width: 20% !important;
		// }

		.link {
			// border-bottom: 1px solid #4965bc;
			color: #4965bc;
			cursor: pointer;
			text-decoration: underline;
			font-size: 14px;
		}

		.full-line {
			width: 100% !important;
			text-align: center;
		}

		.action {
			text-align: right;
		}
		.lines {
			background-color: #ffffff;

			.item {
				padding: 24px;
				// width: 16.6%;
			}
		}
	}

	.pending {
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		/* identical to box height, or 133% */

		/* color / brand / yellow / darkest */

		color: #f98e00;
	}

	.paid {
		/* text-xs/leading-4/font-medium */

		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		/* identical to box height, or 133% */

		/* color / feedback / success */

		color: #00cf72;
	}

	.exclude {
		color: #ff4641;
		border-color: #ff4641;
		margin-top: 10px;
		background-color: transparent;

		margin-top: 24px;

		&:hover {
			background-color: #ffedec;
		}
	}

	.header-button {
		justify-content: right;
	}

	.add-grade {
		margin-left: 8px;
	}

	.container-dhh {
		margin-bottom: 64px !important;
	}

	.empty-phrase {
		.main {
			width: 83.4% !important;
		}

		.generate {
			text-align: right !important;

			justify-content: flex-end;
		}
	}
	.margin-topo-lg {
		margin: 24px;
	}

	.btn-home--minhas-turmas {
		border-radius: 20px;
		border: none;
		background-color: #4965bc !important;
		font-size: 12px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		letter-spacing: 0.7px;
		text-align: center;
		color: #ffffff;
		font-family: "Nunito";
		padding: 12px 20px;
		position: relative;
		top: -13px;
	}

	.nome-da-marca {
		font-family: "Roboto";
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
		color: #68596e;
	}
</style>
